import {
	createContext,
	type PropsWithChildren,
	useContext,
	useState,
} from 'react';

export const VIEW_FORGOT_PASSWORD = 'forgot-password' as const;
export const VIEW_LOGIN = 'login' as const;
export const VIEW_SIGNUP = 'signup' as const;
export const VIEW_VERIFY_EMAIL = 'verify-email' as const;
export const VIEW_VERIFY_PAYMENT = 'verify-payment' as const;

export type View =
	| typeof VIEW_FORGOT_PASSWORD
	| typeof VIEW_LOGIN
	| typeof VIEW_SIGNUP
	| typeof VIEW_VERIFY_EMAIL
	| typeof VIEW_VERIFY_PAYMENT;

export const AuthModalContext = createContext<{
	close: () => void;
	isOpen: boolean;
	openAtView: (view: View) => void;
	setIsOpen: (isOpen: boolean) => void;
	toggle: () => void;
	view: View;
}>({
	close: () => {},
	isOpen: false,
	openAtView: () => {},
	setIsOpen: () => {},
	toggle: () => {},
	view: VIEW_LOGIN,
});
AuthModalContext.displayName = 'AuthModalContext';

export const AuthModalProvider = ({ children }: PropsWithChildren) => {
	const [isOpen, setIsOpen] = useState(false);
	const [view, setView] = useState<View>(VIEW_LOGIN);

	const close = () => {
		setIsOpen(false);
	};

	const openAtView = (view: View) => {
		setView(view);
		setIsOpen(true);
	};

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<AuthModalContext.Provider
			value={{ close, isOpen, openAtView, setIsOpen, toggle, view }}
		>
			{children}
		</AuthModalContext.Provider>
	);
};

export const useAuthModal = () => {
	const context = useContext(AuthModalContext);
	if (!context) {
		throw new Error('useAuthModal must be used within a AuthModalProvider');
	}
	return context;
};
